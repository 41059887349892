import React, { useState, useRef, useEffect } from "react"

import Seo from "../components/seo"
import styled from "styled-components"
import { Link, useStaticQuery, graphql, StaticQuery } from "gatsby"
import { motion, useAnimation } from "framer-motion"
import { PortableText } from "@portabletext/react"

const SinglePage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  z-index: 1;
`
const ContentWrap = styled.div`
  position: absolute;

  max-width: 1200px;
  padding: 0 0 0 0;
  z-index: 1000;
  grid-column-start: 2;
  grid-column-end: 24;
  @media (min-width: 768px) {
    grid-column-start: 6;
    grid-column-end: 21;
  }
  @media (min-width: 1200px) {
    grid-column-start: 8;
    grid-column-end: 18;
  }
  @media (min-width: 1440px) {
    grid-column-start: 7;
    grid-column-end: 19;
  }
  position: relative;
  margin-top: 30px;
  padding-top: 150px;
  padding-bottom: 120px;
  font-family: "PPneueregular";
  color: var(--white);
  .content {
    margin: 30px 0;
    @media (min-width: 768px) {
      margin: 70px 0;
    }
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`

export default function AboutPage({ data }) {
  const about = data.about.nodes[0]

  return (
    <>
      <SinglePage>
        <div className="grid24">
          <ContentWrap
            as={motion.div}
            className="container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.5,
              ease: [0.43, 0.13, 0.23, 0.96],
            }}
          >
            <div className="content">
              <PortableText value={about._rawText} />
            </div>
          </ContentWrap>
        </div>
      </SinglePage>
    </>
  )
}

export const query = graphql`
  query aboutQuery {
    about: allSanityAbout(filter: { _id: { eq: "singleton-about" } }) {
      nodes {
        title
        _rawText
      }
    }
  }
`
